'<template>
	<div class="aboutContainer">
		<div class="banner"></div>
		<div class="content_container">
			<div class="font1">企业资质</div>
			<div class="font2"><span class="font2-text">ENTERPRISE QUALIFICATION</span><span class="splitLine"></span></div>
			<div class="swiper-container swiper-no-swiping">
				<div class="swiper-wrapper">
					<div class="swiper-slide"><img src="../../public/images/certificate1.png" /></div>
					<div class="swiper-slide"><img src="../../public/images/certificate2.png" />

					</div>
					<div class="swiper-slide"><img src="../../public/images/certificate3.png" />

					</div>
					<div class="swiper-slide"><img src="../../public/images/certificate4.png" />

					</div>
					<div class="swiper-slide"><img src="../../public/images/certificate5.png" />
					</div>
					<div class="swiper-slide"><img src="../../public/images/certificate6.png" />
					</div>
					<div class="swiper-slide"><img src="../../public/images/certificate7.png" />
					</div>
				</div>
			</div>
			<div class="swiper-pagination"></div>
			<div class="swiper-button-prev"></div>
			<div class="swiper-button-next"></div>
		</div>
	</div>
</template>

<script>
	import Swiper from 'swiper'
	import '../assets/swiper.min.css'
	import '../assets/animate.min.css'
	//import 'swiper/swiper-bundle.css'
	//import "swiper/dist/css/swiper.min.css"
	export default {
		data() {
			return {
				mySwiper: {}
			}
		},
		computed: {

		},
		mounted() {
			let _this = this;
			window.setTimeout(function(){
				_this.initSwiper()
				console.log("init");
			},1000)
			
		},
		methods: {
			initSwiper: function() {
				let _this = this;
				_this.mySwiper = new Swiper('.swiper-container', {
					watchSlidesProgress: true,
					slidesPerView: 'auto',
					centeredSlides: true,
					loopedSlides:3,
					autoplay: true,
					loop:true,
					loopAdditionalSlides:3,
					mousewheel: true,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					pagination: {
						el: '.swiper-pagination',
						clickable: true,
					},
					on: {
						progress: function() {
							console.log(this);
							for (var i = 0; i < this.slides.length; i++) {
								var slide = this.slides.eq(i);
								var slideProgress = this.slides[i].progress;
								var modify = 1;
								if (Math.abs(slideProgress) > 1) {
									modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
								}
								var translate = slideProgress * modify * 260 + 'px';
								var scale = 1 - Math.abs(slideProgress) / 5;
								var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
								slide.transform('translateX(' + translate + ') scale(' + scale + ')');
								slide.css('zIndex', zIndex);
								slide.css('opacity', 1);
								if (Math.abs(slideProgress) > 3) {
									slide.css('opacity', 0);
								}
							}
						},
						setTransition: function(transition) {
							for (var i = 0; i < this.slides.length; i++) {
								var slide = this.slides.eq(i)
								slide.transition(transition);
							}

						}
					}
				});

			}
		}
	}
</script>

<style>
	.aboutContainer {
		min-height: 800px;
		background-color: #175068;
	}

	.banner {
		width: 100%;
		height: 10rem;
		background: url(../../public/images/introductionbanner.png) no-repeat;
		background-size: 100% 100%;
	}

	.content_container {
		position: relative;
		width: 1200px;
		margin: 0 auto
	}


	.content_container .swiper-slide {
		width: 520px;
		height: 800px;
		position: relative;
	}

	.content_container .swiper-slide img {
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.content_container .swiper-slide p {
		line-height: 98px;
		padding-top: 0;
		text-align: center;
		color: #636363;
		font-size: 1.1em;
		margin: 0;
	}

	.content_container .swiper-pagination {
		width: 100%;
		bottom: 20px;
	}

	.content_container .swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 5px;
		border: 3px solid #fff;
		background-color: #d5d5d5;
		width: 10px;
		height: 10px;
		opacity: 1;
	}

	.content_container .swiper-pagination-bullets .swiper-pagination-bullet-active {
		border: 3px solid #00aadc;
		background-color: #fff;
	}

	.content_container .swiper-button-prev {

		outline: none;
	}


	.content_container .swiper-button-next {

		outline: none;
	}

	.swiper-pagination-bullet {
		outline: none;
	}

	/* .swiper-wrapper {
		transition: all 300ms ease 0s !important;
	} */
</style>
